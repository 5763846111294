<template>
  <canvas ref="canvasRef" class="bounce-battle-game" :style="`height:${height}px;`"></canvas>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { computed, defineComponent, nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import type {
  GameBounceBattleModel,
  GameBounceBattleVisualsPaddleState
} from '@/src/components/games/bounce-battle/Model';
import { useUtilityStore } from '@/src/store/utility';
import type { MetricData } from '@/src/store/campaign';
import { useCampaignStore } from '@/src/store/campaign';
import useDevice, { getDeviceHeight } from '@/src/hooks/useDevice';
import { endGame } from '@/src/hooks/useEndGame';

enum DIRECTION {
  IDLE,
  UP,
  DOWN,
  LEFT,
  RIGHT
}

interface BounceBattlePaddle {
  type: PaddleType;
  width: number;
  height: number;
  x: number;
  y: number;
  score: number;
  direction: DIRECTION;
  speed: number;
  touchControl?: boolean;
}

interface Ball {
  width: number;
  height: number;
  x: number;
  y: number;
  velocityX: number;
  velocityY: number;
  speed: number;
  alpha: number;
  prevPosition: {
    x: number;
    y: number;
  };
}

export enum Orientation {
  PORTRAIT,
  LANDSCAPE
}

enum PaddleType {
  PLAYER = 'player',
  ENEMY = 'enemy'
}

interface KeyMappings {
  [key: string]: number;
}

interface BounceBattleMetricData extends MetricData {
  score: number;
  timeused: number;
  timeused_ms: number;
}

// Paddle config
const PADDLE_WIDTH = 70;
const PADDLE_HEIGHT = 18;
const PADDLE_MARGIN = 20;

// Ball config
const BALL_SIZE = 10;

// Keybindings
const KEY_MAPPINGS: KeyMappings = {
  ArrowUp: DIRECTION.UP,
  KeyW: DIRECTION.UP,
  ArrowDown: DIRECTION.DOWN,
  KeyS: DIRECTION.DOWN,
  ArrowLeft: DIRECTION.LEFT,
  KeyA: DIRECTION.LEFT,
  ArrowRight: DIRECTION.RIGHT,
  KeyD: DIRECTION.RIGHT
};

export default defineComponent({
  name: 'GameBounceBattle',
  props: {
    model: {
      type: Object as PropType<GameBounceBattleModel>,
      required: true
    }
  },

  setup(props) {
    const campaignStore = useCampaignStore();
    const utilityStore = useUtilityStore();
    const canvasRef = ref<HTMLCanvasElement | null>(null);

    let paddlePlayer: BounceBattlePaddle = {
      type: PaddleType.PLAYER,
      width: PADDLE_WIDTH,
      height: PADDLE_HEIGHT,
      x: 0,
      y: 0,
      score: 0,
      direction: DIRECTION.IDLE,
      speed: 0,
      touchControl: false
    };

    let paddleBot: BounceBattlePaddle = {
      type: PaddleType.ENEMY,
      width: PADDLE_WIDTH,
      height: PADDLE_HEIGHT,
      x: 0,
      y: 0,
      score: 0,
      direction: DIRECTION.IDLE,
      speed: 0
    };

    let isServing = true;
    const containerBounds = ref<DOMRect>();
    const height = ref(0);

    const ball: Ball = {
      width: BALL_SIZE,
      height: BALL_SIZE,
      x: 0,
      y: 0,
      alpha: 1,
      velocityX: 0,
      velocityY: 0,
      speed: props.model.state.general.ballSpeed,
      prevPosition: {
        x: 0,
        y: 0
      }
    };

    const loadedImages: { [key: string]: HTMLImageElement } = {};
    const playerScore = ref(0);
    const aiScore = ref(0);
    const timeused = ref(0);
    const gameStartTime = ref<number | undefined>();
    const gameEndTime = ref<number | undefined>();
    const isDemo = (utilityStore.url ?? window.location.href).includes('/campaign/view/demo');
    const gameOrientation = props.model.state.visuals.type;

    let gameRunning = false;
    let gameEnded = false;
    let gameTimer: number | undefined;
    let gameStarted = false;
    let firstRound = true;
    let lastFrameTime = 0;
    let animationFrameId: number | null = null;
    let timer = 0;
    let intervalTimer = 0;
    let readyPromiseResolve: (() => void) | undefined;

    const readyPromise = new Promise<void>((resolve) => {
      readyPromiseResolve = resolve;
    });

    const timeusedMs = computed(() => (gameEndTime.value ?? Date.now()) - (gameStartTime.value ?? Date.now()));
    const metricData = computed<BounceBattleMetricData>(() => {
      return {
        timeused: timeused.value,
        timeused_ms: timeusedMs.value,
        score: playerScore.value,
        opponent_score: aiScore.value,
        timeleft:
          props.model.state.timeChallenge?.enabled && props.model.state.timeChallenge.limit
            ? Math.max(0, props.model.state.timeChallenge.limit - timeused.value)
            : 0
      };
    });

    const resetBall = () => {
      if (!canvasRef.value) return;
      const { width, height } = canvasRef.value;
      const halfWidth = width / 2;
      const halfHeight = height / 2;

      ball.alpha = 0;
      ball.x = halfWidth - props.model.state.visuals.ball.size / 2;
      ball.y = halfHeight - props.model.state.visuals.ball.size / 2;
      ball.velocityX = 0;
      ball.velocityY = 0;
      ball.speed = props.model.state.general.ballSpeed;
    };

    const createPaddle = (
      type: PaddleType,
      paddleWidth: number,
      paddleHeight: number,
      canvasWidth: number,
      canvasHeight: number,
      imageType: string
    ): BounceBattlePaddle => {
      let w = paddleHeight;
      let h = paddleWidth;

      if (gameOrientation === Orientation.LANDSCAPE && imageType === 'image') {
        w = paddleWidth;
        h = paddleHeight;
      }

      let x: number | undefined;
      let y: number | undefined;

      y = canvasHeight / 2 - h / 2;

      if (gameOrientation === Orientation.PORTRAIT) {
        [w, h] = [paddleWidth, paddleHeight];
        x = canvasWidth / 2 - h / 2;
        y = type === PaddleType.PLAYER ? canvasHeight - (PADDLE_MARGIN + h) : PADDLE_MARGIN;
      } else {
        x = type === PaddleType.PLAYER ? canvasWidth - (PADDLE_MARGIN + w) : PADDLE_MARGIN;
      }

      let speed = props.model.state.general.speed;
      if (type === PaddleType.PLAYER) {
        speed = 800;
      }

      return {
        type,
        width: w,
        height: h,
        x,
        y,
        score: 0,
        direction: DIRECTION.IDLE,
        speed
      };
    };

    const getPaddleDimensions = (
      paddleInfo: GameBounceBattleVisualsPaddleState,
      defaultWidth: number,
      defaultHeight: number,
      paddleType: PaddleType,
      maxWidth?: number
    ) => {
      let width = Number(paddleInfo.length) ?? defaultWidth;
      let height = Number(paddleInfo.thickness) ?? defaultHeight;

      if (paddleInfo.type === 'image' && Object.hasOwnProperty.call(loadedImages, paddleType)) {
        width = loadedImages[`${paddleType}`].width;
        height = loadedImages[`${paddleType}`].height;
      }

      if (maxWidth && paddleInfo.type === 'image' && width > maxWidth) {
        // For image paddles, adjust height to maintain aspect ratio
        const aspectRatio = width / height;
        width = maxWidth;
        height = maxWidth / aspectRatio;
      }

      return { width, height };
    };

    const initPaddles = () => {
      if (!canvasRef.value) return;

      const { width, height } = canvasRef.value;
      const { player, enemy, differentLayout } = props.model.state.visuals.paddles;

      const playerMaxWidth = props.model.state.visuals.paddles.player?.image?.maxWidth;

      const { width: paddleWidth, height: paddleHeight } = getPaddleDimensions(
        player,
        PADDLE_WIDTH,
        PADDLE_HEIGHT,
        PaddleType.PLAYER,
        playerMaxWidth
      );

      const botMaxWidth = props.model.state.visuals.paddles.enemy?.image?.maxWidth;

      let { width: paddleBotWidth, height: paddleBotHeight } = getPaddleDimensions(
        enemy ?? player,
        PADDLE_WIDTH,
        PADDLE_HEIGHT,
        PaddleType.ENEMY,
        botMaxWidth
      );

      if (!differentLayout) {
        paddleBotWidth = paddleWidth;
        paddleBotHeight = paddleHeight;
      }

      const playerImageType = props.model.state.visuals.paddles.player.type;
      const enemyImageType = props.model.state.visuals.paddles.enemy?.type ?? playerImageType;

      paddlePlayer = createPaddle(PaddleType.PLAYER, paddleWidth, paddleHeight, width, height, playerImageType);
      paddleBot = createPaddle(PaddleType.ENEMY, paddleBotWidth, paddleBotHeight, width, height, enemyImageType);
    };

    const initialize = () => {
      const canvas = canvasRef.value;
      if (!canvas) return;

      initPaddles();

      if (loadedImages.ball && props.model.state.visuals.ball.type === 'image') {
        ball.width = loadedImages.ball.width;
        ball.height = loadedImages.ball.height;

        const maxWidth = props.model.state.visuals.ball.image?.maxWidth;
        if (maxWidth && ball.width > maxWidth) {
          const aspectRatio = ball.width / ball.height;
          ball.width = maxWidth;
          ball.height = maxWidth / aspectRatio;
        }
      } else {
        ball.height = props.model.state.visuals.ball.size;
        ball.width = props.model.state.visuals.ball.size;
      }

      if (!paddlePlayer || !paddleBot || !ball) return;

      gameRunning = false;
      gameEnded = false;
      isServing = true;
      timer = 0;
    };

    const drawBall = (context: CanvasRenderingContext2D, containerWidth: number, containerHeight: number) => {
      if (!containerBounds.value) return;

      if (turnDelayIsOver()) {
        ball.alpha = 1; // fully visible
      } else if (ball.alpha < 1) {
        ball.alpha += 0.05; // or any increment to make it fade in
        ball.alpha = Math.min(1, ball.alpha); // don't go beyond 1
      }

      context.globalAlpha = ball.alpha;

      // Initialize ball at the center of the canvas if it's time to serve
      if (isServing) {
        ball.x = containerWidth / 2 - ball.width / 2;
        ball.y = containerHeight / 2 - ball.height / 2;
      }

      if (loadedImages.ball && props.model.state.visuals.ball.type === 'image') {
        context.drawImage(loadedImages.ball, ball.x, ball.y, ball.width, ball.height);
      } else {
        const ballRadius = ball.width / 2;
        context.beginPath();
        context.fillStyle = props.model.state.visuals.ball.color ?? '#ffffff';
        context.arc(ball.x + ballRadius, ball.y + ballRadius, ballRadius, 0, Math.PI * 2, false);
        context.fill();
        context.closePath();
      }

      context.globalAlpha = 1; // reset alpha
    };

    const drawImagePaddle = (
      image: HTMLImageElement,
      paddle: BounceBattlePaddle,
      context: CanvasRenderingContext2D
    ) => {
      context.drawImage(image, paddle.x, paddle.y, paddle.width, paddle.height);
    };

    const drawPaddles = (context: CanvasRenderingContext2D) => {
      const playerImage = loadedImages.player;
      const enemyImage = loadedImages.enemy;

      const useDifferentLayout = props.model.state.visuals.paddles.differentLayout;

      const drawRectPaddle = (paddle: BounceBattlePaddle, color?: string) => {
        context.fillStyle = color ?? '#ffffff';
        context.fillRect(paddle.x, paddle.y, paddle.width, paddle.height);
      };

      const player = props.model.state.visuals.paddles.player;
      const enemy = props.model.state.visuals.paddles.enemy;

      const playerPaddle = paddlePlayer;
      const enemyPaddle = paddleBot;

      if (useDifferentLayout) {
        player.type === 'icon'
          ? drawRectPaddle(playerPaddle, player.color)
          : drawImagePaddle(playerImage, playerPaddle, context);
        enemy?.type === 'icon'
          ? drawRectPaddle(enemyPaddle, enemy.color)
          : drawImagePaddle(enemyImage, enemyPaddle, context);
      } else if (player.type === 'icon') {
        drawRectPaddle(playerPaddle, player.color);
        drawRectPaddle(enemyPaddle, player.color);
      } else {
        drawImagePaddle(playerImage, playerPaddle, context);
        drawImagePaddle(playerImage, enemyPaddle, context);
      }
    };

    const drawDivider = (context: CanvasRenderingContext2D, width: number, height: number) => {
      context.beginPath();
      if (props.model.state.visuals.divider.gap) {
        context.setLineDash([props.model.state.visuals.divider.gap, props.model.state.visuals.divider.gap]);
      }
      if (gameOrientation === Orientation.LANDSCAPE) {
        const center = width / 2;
        context.moveTo(center, height);
        context.lineTo(center, 0);
      } else {
        const center = height / 2;
        context.moveTo(0, center);
        context.lineTo(width, center);
      }
      context.lineWidth = props.model.state.visuals.divider?.thickness ?? 0;
      context.strokeStyle = props.model.state.visuals.divider.color ?? '';
      context.stroke();
    };

    const drawGame = () => {
      if (!canvasRef.value || !ball || !paddlePlayer || !paddleBot) return;

      const canvas = canvasRef.value;
      const { width, height } = canvas;

      if (!canvas) return;

      const context = canvas.getContext('2d');

      if (!context) return;

      context.clearRect(0, 0, canvas.width, canvas.height);

      // Draw the net (Line in the middle)
      if (props.model.state.visuals.divider.enabled) {
        drawDivider(context, width, height);
      }

      // Draw Player and Paddle
      drawPaddles(context);

      // Draw Ball
      drawBall(context, width, height);
    };

    const renderLoop = (timestamp = 0) => {
      // Ensure deltaTime does not exceed a value corresponding to 60 FPS
      const deltaTime = Math.min((timestamp - lastFrameTime) / 1000, 1);

      // Schedule the next frame if the game is running
      if (!gameEnded) {
        animationFrameId = requestAnimationFrame(renderLoop);
      }

      lastFrameTime = timestamp;

      // Cancel updates if the tab is hidden
      if (document.hidden) {
        if (animationFrameId) {
          cancelAnimationFrame(animationFrameId);
        }
        return;
      }

      // Use a fixed timestep for consistent updates (50 FPS = 0.02 seconds per frame)
      const fixedStep = 0.02;
      const iterations = Math.floor(deltaTime / fixedStep);

      // Apply updates for each fixed step
      for (let i = 0; i < iterations; i++) {
        updateGame(fixedStep);
      }

      // Handle any leftover time after the fixed steps
      const secondsLeft = deltaTime % fixedStep;
      if (secondsLeft > 0) {
        updateGame(secondsLeft);
      }

      drawGame();
    };

    const handleBallBoundCollision = (canvas: HTMLCanvasElement) => {
      if (gameOrientation === Orientation.LANDSCAPE) {
        // Handle ball-bound collision in landscape mode
        if (ball.x <= 0) {
          resetTurn(paddlePlayer);
        } else if (ball.x >= canvas.width - ball.width) {
          resetTurn(paddleBot);
        }

        if (ball.y <= 0) {
          ball.velocityY = Math.abs(ball.velocityY);
        } else if (ball.y >= canvas.height - ball.height) {
          ball.velocityY = -Math.abs(ball.velocityY);
        }
      } else {
        // Handle ball-bound collision in portrait mode
        if (ball.y <= 0) {
          resetTurn(paddlePlayer);
        } else if (ball.y >= canvas.height - ball.height) {
          resetTurn(paddleBot);
        }

        if (ball.x <= 0) {
          ball.velocityX = Math.abs(ball.velocityX);
        } else if (ball.x >= canvas.width - ball.width) {
          ball.velocityX = -Math.abs(ball.velocityX);
        }
      }
    };

    const handlePaddlePlayerMove = (elapsedTime: number) => {
      if (paddlePlayer.touchControl) return; // Skip automatic movement if manual control is active

      if (useDevice().isDesktop) {
        if (gameOrientation === Orientation.PORTRAIT) {
          if (paddlePlayer.direction === DIRECTION.LEFT) {
            paddlePlayer.x -= paddlePlayer.speed * elapsedTime;
          } else if (paddlePlayer.direction === DIRECTION.RIGHT) {
            paddlePlayer.x += paddlePlayer.speed * elapsedTime;
          }
        } else if (gameOrientation === Orientation.LANDSCAPE) {
          if (paddlePlayer.direction === DIRECTION.UP) {
            paddlePlayer.y -= paddlePlayer.speed * elapsedTime;
          } else if (paddlePlayer.direction === DIRECTION.DOWN) {
            paddlePlayer.y += paddlePlayer.speed * elapsedTime;
          }
        }
      }
    };

    const handleNewServe = () => {
      if (!containerBounds.value) return;

      if (turnDelayIsOver() && isServing && gameStarted) {
        // Set ball at the center of the canvas
        ball.x = containerBounds.value.width / 2 - ball.width / 2;
        ball.y = containerBounds.value.height / 2 - ball.height / 2;

        let horizontalRatio, verticalRatio;

        // Randomize direction for both axes
        const directionX = Math.random() > 0.5 ? 1 : -1;
        const directionY = Math.random() > 0.5 ? 1 : -1;

        if (gameOrientation === Orientation.LANDSCAPE) {
          // In landscape mode: horizontal movement dominates
          const minHorizontalRatio = 0.9;
          const maxHorizontalRatio = 0.99; // Prevents too steep angles
          horizontalRatio = Math.random() * (maxHorizontalRatio - minHorizontalRatio) + minHorizontalRatio;
          verticalRatio = Math.sqrt(1 - horizontalRatio ** 2);
        } else {
          // In portrait mode: vertical movement dominates
          const minVerticalRatio = 0.9;
          const maxVerticalRatio = 0.99;
          verticalRatio = Math.random() * (maxVerticalRatio - minVerticalRatio) + minVerticalRatio;
          horizontalRatio = Math.sqrt(1 - verticalRatio ** 2);
        }

        // Assign velocities
        ball.velocityX = horizontalRatio * directionX * ball.speed;
        ball.velocityY = verticalRatio * directionY * ball.speed;

        // Mark the ball as in play
        isServing = false;
      }
    };

    const handlePaddleBoundCollision = (paddle: BounceBattlePaddle, canvas: HTMLCanvasElement) => {
      if (gameOrientation === Orientation.LANDSCAPE) {
        // Handle paddle bound collision in landscape mode
        if (paddle.y <= 0) {
          paddle.y = 0;
        } else if (paddle.y >= canvas.height - paddle.height) {
          paddle.y = canvas.height - paddle.height;
        }
      } else if (gameOrientation === Orientation.PORTRAIT) {
        // Handle paddle bound collision in portrait mode
        if (paddle.x <= 0) {
          paddle.x = 0;
        } else if (paddle.x >= canvas.width - paddle.width) {
          paddle.x = canvas.width - paddle.width;
        }
      }
    };

    const handleBallMovement = (elapsedTime: number) => {
      if (!gameStarted) return;
      ball.x += ball.velocityX * elapsedTime;
      ball.y += ball.velocityY * elapsedTime;
    };

    const handlePaddleBotMove = (elapsedTime: number) => {
      let speedAdjustmentFactor = 1;

      if (gameOrientation === Orientation.LANDSCAPE) {
        const ballMiddleY = ball.y + ball.height / 2;
        const paddleMiddleY = paddleBot.y + paddleBot.height / 2;
        const delta = ballMiddleY - paddleMiddleY;
        const distance = ballMiddleY - (paddleBot.y + paddleBot.height / 2);

        speedAdjustmentFactor = Math.min(Math.abs(distance) / 100, 1);
        paddleBot.y += Math.sign(delta) * paddleBot.speed * elapsedTime * speedAdjustmentFactor;
      } else {
        const ballMiddleX = ball.x + ball.width / 2;
        const paddleMiddleX = paddleBot.x + paddleBot.width / 2;
        const delta = ballMiddleX - paddleMiddleX;
        const distance = ballMiddleX - (paddleBot.x + paddleBot.width / 2);

        speedAdjustmentFactor = Math.min(Math.abs(distance) / 100, 1);
        paddleBot.x += Math.sign(delta) * paddleBot.speed * elapsedTime * speedAdjustmentFactor;
      }
    };

    const lerp = (start: number, end: number, t: number) => start + t * (end - start);

    const checkBallPaddleCollision = (paddle: BounceBattlePaddle) => {
      if (!isCollidingWithPaddle(paddle)) {
        // Check for tunneling
        const { x: prevX, y: prevY } = ball.prevPosition;
        const { x: nextX, y: nextY } = ball;

        // Linear interpolation (lerp) between previous and next ball positions
        for (let t = 0; t <= 1; t += 0.1) {
          ball.x = lerp(prevX, nextX, t);
          ball.y = lerp(prevY, nextY, t);

          if (isCollidingWithPaddle(paddle)) {
            handleCollisionResponse(paddle);
            return;
          }
        }
      } else {
        handleCollisionResponse(paddle);
      }
    };

    const isWithinRange = (min1: number, max1: number, min2: number, max2: number) => {
      return min1 <= max2 && max1 >= min2;
    };

    const isCollidingWithPaddle = (paddle: BounceBattlePaddle) => {
      // Basic bounding-box overlap
      const isHorizontalCollision = isWithinRange(ball.x, ball.x + ball.width, paddle.x, paddle.x + paddle.width);
      const isVerticalCollision = isWithinRange(ball.y, ball.y + ball.height, paddle.y, paddle.y + paddle.height);
      const isBasicCollision = isHorizontalCollision && isVerticalCollision;

      if (!isBasicCollision) return false;

      if (gameOrientation === Orientation.LANDSCAPE) {
        // Ensure collision happens only on the edge of the paddle
        if (paddle.type === 'enemy') {
          return (
            ball.velocityX < 0 &&
            ball.x <= paddle.x + paddle.width &&
            ball.x >= paddle.x + paddle.width - paddle.width / 2
          );
        } else {
          return (
            ball.velocityX > 0 && ball.x + ball.width >= paddle.x && ball.x + ball.width <= paddle.x + paddle.width / 2
          );
        }
      } else {
        // Vertical paddles (top and bottom)
        if (paddle.type === 'player') {
          return (
            ball.velocityY > 0 &&
            ball.y + ball.height >= paddle.y &&
            ball.y + ball.height <= paddle.y + paddle.height / 2
          );
        } else {
          return (
            ball.velocityY < 0 &&
            ball.y <= paddle.y + paddle.height &&
            ball.y >= paddle.y + paddle.height - paddle.height / 2
          );
        }
      }
    };

    const handleCollisionResponse = (paddle: BounceBattlePaddle) => {
      const ballCenter = gameOrientation === Orientation.LANDSCAPE ? ball.y + ball.height / 2 : ball.x + ball.width / 2;

      const paddleCenter =
        gameOrientation === Orientation.LANDSCAPE ? paddle.y + paddle.height / 2 : paddle.x + paddle.width / 2;

      const relativeImpact = (ballCenter - paddleCenter) / (paddle.height / 2);

      const relativeImpactMultiplier = 10;
      const paddleInfluenceMultiplier = 300;
      let paddleInfluence = 0;

      if (gameOrientation === Orientation.LANDSCAPE) {
        // Paddles on left/right, so reversing X on collision
        paddleInfluence =
          paddle.direction === DIRECTION.UP
            ? -paddle.speed * 0.001
            : paddle.direction === DIRECTION.DOWN
            ? paddle.speed * 0.001
            : 0;

        ball.velocityX = -ball.velocityX;

        ball.velocityY += relativeImpact * relativeImpactMultiplier + paddleInfluence * paddleInfluenceMultiplier;
      } else {
        // Paddles on top/bottom, so reversing Y on collision
        paddleInfluence =
          paddle.direction === DIRECTION.LEFT
            ? -paddle.speed * 0.001
            : paddle.direction === DIRECTION.RIGHT
            ? paddle.speed * 0.001
            : 0;

        ball.velocityY = -ball.velocityY;

        ball.velocityX += relativeImpact * relativeImpactMultiplier + paddleInfluence * paddleInfluenceMultiplier;
      }

      // Normalize velocity so speed remains consistent
      const magnitude = Math.sqrt(ball.velocityX ** 2 + ball.velocityY ** 2);
      ball.velocityX = (ball.velocityX / magnitude) * ball.speed;
      ball.velocityY = (ball.velocityY / magnitude) * ball.speed;

      ball.speed += 20;

      // Get the current angle [0..2π)
      let angle = Math.atan2(ball.velocityY, ball.velocityX);
      if (angle < 0) angle += 2 * Math.PI;

      // We'll keep at least 15° away from pure horizontal
      const MIN_ANGLE = (15 * Math.PI) / 180;

      // Clamp away from near 0° or near 180° (pure horizontal)
      if (angle < MIN_ANGLE) {
        angle = MIN_ANGLE;
      } else if (angle > Math.PI - MIN_ANGLE && angle < Math.PI + MIN_ANGLE) {
        // near 180°
        angle = angle <= Math.PI ? Math.PI - MIN_ANGLE : Math.PI + MIN_ANGLE;
      } else if (angle > 2 * Math.PI - MIN_ANGLE) {
        angle = 2 * Math.PI - MIN_ANGLE;
      }

      // Reapply the clamped angle with the newly increased speed
      ball.velocityX = ball.speed * Math.cos(angle);
      ball.velocityY = ball.speed * Math.sin(angle);

      // Shift ball so it's on the correct side of the paddle
      if (gameOrientation === Orientation.LANDSCAPE) {
        // Paddles left/right
        ball.x = paddle.x + (ball.velocityX > 0 ? paddle.width + 1 : -ball.width - 1);
      } else {
        // Paddles top/bottom
        ball.y = paddle.y + (ball.velocityY > 0 ? paddle.height + 1 : -ball.height - 1);
      }
    };
    const updateGame = (elapsedTime: number) => {
      if (!canvasRef.value || !ball || !paddlePlayer || !paddleBot) return;
      const canvas = canvasRef.value;

      if (!(canvas instanceof HTMLCanvasElement)) return;

      // On new serve (start of each turn) move the ball to the correct side
      handleNewServe();

      // If the ball collides with the bound limits - correct the x and y coords.
      handleBallBoundCollision(canvas);

      // Move player/AI - if the player move value was updated by a keyboard event
      handlePaddlePlayerMove(elapsedTime);
      handlePaddleBotMove(elapsedTime);

      // If the player collides with the bound limits, update the x and y coords.
      handlePaddleBoundCollision(paddlePlayer, canvas);
      handlePaddleBoundCollision(paddleBot, canvas);

      // Move ball in the intended direction based on moveYDirection and moveXDirection values
      if (!isServing) {
        handleBallMovement(elapsedTime);
        handleBallPaddleCollisions();
        ball.prevPosition = { x: ball.x, y: ball.y };
      }
    };

    const handleBallPaddleCollisions = () => {
      checkBallPaddleCollision(paddlePlayer);
      checkBallPaddleCollision(paddleBot);
    };

    // Wait for a delay to have passed after each turn.
    const turnDelayIsOver = () => {
      return new Date().getTime() - timer >= 1000;
    };

    // Reset the ball location, the player turns and set a delay before the next round begins.
    const resetTurn = (victor: BounceBattlePaddle) => {
      if (!firstRound) {
        if (gameStarted && victor === paddlePlayer) {
          playerScore.value++;
        } else {
          aiScore.value++;
        }
      }

      firstRound = false;

      isServing = true;

      paddleBot.speed = props.model.state.general.speed;
      paddlePlayer.speed = 800;

      resetBall();

      timer = new Date().getTime();

      victor.score++;
    };

    const updateTime = () => {
      timeused.value++;

      if (
        props.model.state.timeChallenge?.enabled &&
        props.model.state.timeChallenge.limit &&
        // allow it to reach 0 before ending
        timeused.value === props.model.state.timeChallenge.limit + 1
      ) {
        gameEnded = true;
        endGame(false);
      }
    };

    const startGame = () => {
      drawGame();
      if (campaignStore.hasGamePopover) return;

      resetTurn(paddleBot);

      if (!gameRunning) {
        gameStartTime.value = Date.now();
        intervalTimer = window.setInterval(updateTime, 1000);

        gameRunning = true;
        gameStarted = true;

        lastFrameTime = Date.now();
        animationFrameId = window.requestAnimationFrame(renderLoop);
      }
    };

    const onKeyUp = (key: KeyboardEvent) => {
      const lastDirection = KEY_MAPPINGS[key.code];

      if (!lastDirection || !paddlePlayer) return;

      // If the direction in which the paddle was moving last matches the key that was released,
      // stop the paddle. Otherwise, keep it moving in its current direction.
      // prevents that it sometimes bugs when pressing two keys at the same time
      if (lastDirection === paddlePlayer.direction) {
        paddlePlayer.direction = DIRECTION.IDLE;
      }
    };

    const onKeyDown = (key: KeyboardEvent) => {
      const direction = KEY_MAPPINGS[key.code];
      if (!direction || !paddlePlayer) return;
      key.preventDefault();
      paddlePlayer.touchControl = false;
      paddlePlayer.direction = direction;
    };

    const onTouchStart = (e: TouchEvent | MouseEvent) => {
      e.preventDefault();
      if (!paddlePlayer || !containerBounds.value) return;

      const clientX = 'touches' in e ? e.touches[0].clientX : e.clientX;
      const clientY = 'touches' in e ? e.touches[0].clientY : e.clientY;

      const relativeX = clientX - (containerBounds.value?.left ?? 0);
      const relativeY = clientY - (containerBounds.value?.top ?? 0);

      if (gameOrientation === Orientation.PORTRAIT) {
        paddlePlayer.x = relativeX - paddlePlayer.width / 2;
      } else {
        paddlePlayer.y = relativeY - paddlePlayer.height / 2;
      }
    };

    const onTouchMove = (e: TouchEvent | MouseEvent) => {
      e.preventDefault();

      if (!paddlePlayer || !containerBounds.value) return;

      const clientX = 'touches' in e ? e.touches[0].clientX : e.clientX;
      const clientY = 'touches' in e ? e.touches[0].clientY : e.clientY;

      const relativeX = clientX - containerBounds.value.left;
      const relativeY = clientY - containerBounds.value.top;

      if (gameOrientation === Orientation.PORTRAIT) {
        const prevX = paddlePlayer.x;
        paddlePlayer.x = Math.max(
          0,
          Math.min(containerBounds.value.width - paddlePlayer.width, relativeX - paddlePlayer.width / 2)
        );

        paddlePlayer.direction =
          paddlePlayer.x > prevX ? DIRECTION.RIGHT : paddlePlayer.x < prevX ? DIRECTION.LEFT : DIRECTION.IDLE;
      } else {
        const prevY = paddlePlayer.y;
        paddlePlayer.y = Math.max(
          0,
          Math.min(containerBounds.value.height - paddlePlayer.height, relativeY - paddlePlayer.height / 2)
        );

        paddlePlayer.direction =
          paddlePlayer.y > prevY ? DIRECTION.DOWN : paddlePlayer.y < prevY ? DIRECTION.UP : DIRECTION.IDLE;
      }

      paddlePlayer.touchControl = true; // Indicate that the paddle is being controlled manually
    };

    const onResize = () => {
      if (props.model.state.visuals.height.includes('px')) {
        height.value = Number(props.model.state.visuals.height.replace('px', ''));
      } else if (props.model.state.visuals.height.includes('vh')) {
        height.value = (getDeviceHeight() / 100) * Number(props.model.state.visuals.height.replace('vh', ''));
      }

      setSize();

      containerBounds.value = canvasRef.value?.getBoundingClientRect();
    };

    const setSize = () => {
      if (canvasRef.value instanceof HTMLCanvasElement) {
        canvasRef.value.width = canvasRef.value.offsetWidth;
        canvasRef.value.height = height.value;
      }
    };

    const onScroll = () => {
      containerBounds.value = canvasRef.value?.getBoundingClientRect();
    };

    const finishGame = (winner: boolean) => {
      if (gameEnded) {
        return;
      }

      gameEnded = true;
      gameEndTime.value = Date.now();

      setTimeout(() => {
        endGame(winner);
      }, 500);
    };

    watch(
      metricData,
      () => {
        campaignStore.addReplacementTags(metricData.value);
        campaignStore.metricData = metricData.value;

        if (campaignStore.model?.state.isEditModeActive) {
          return;
        }

        if (
          props.model.state.timeChallenge?.enabled &&
          props.model.state.timeChallenge.limit &&
          timeused.value >= props.model.state.timeChallenge.limit
        ) {
          finishGame(false);
          return;
        }

        if (playerScore.value >= props.model.state.general.pointsToWin) {
          finishGame(true);
        } else if (aiScore.value >= props.model.state.general.pointsToWin) {
          finishGame(false);
        }
      },
      {
        immediate: true
      }
    );

    const preloadImages = async () => {
      const imagesToPreload: { key: string; src: string | undefined }[] = [
        { key: 'ball', src: props.model.state.visuals.ball?.image?.src },
        { key: 'player', src: props.model.state.visuals.paddles.player?.image?.src },
        { key: 'enemy', src: props.model.state.visuals.paddles.enemy?.image?.src }
      ];

      const loadImage = (src: string): Promise<HTMLImageElement> => {
        return new Promise<HTMLImageElement>((resolve, reject) => {
          const image = new Image();
          image.onload = () => resolve(image);
          image.onerror = (error) => reject(error);
          image.src = src;
        });
      };

      await Promise.all(
        imagesToPreload.map(async (item) => {
          if (item.src) {
            try {
              loadedImages[item.key] = await loadImage(item.src);
            } catch (error) {
              // eslint-disable-next-line no-console
              console.error(`Failed to load ${item.key} image: ${item.src}`, error);
            }
          }
        })
      );
    };

    if (isDemo) {
      const reload = async () => {
        await nextTick();

        // Allow the DOM some time to update based on the patch request from Vue.
        // This is because we rely on the width of the DOM canvas to get the right
        // size for the canvas.
        setTimeout(onResize, 10);
        setTimeout(onResize, 50);
        setTimeout(onResize, 100);
        setTimeout(onResize, 150);
        setTimeout(initialize, 150);
      };

      watch(() => useDevice(), reload, {
        deep: true
      });
    }

    watch(
      () => campaignStore.hasGamePopover,
      () => {
        startGame();
      }
    );

    const visibilityChangeHandler = () => {
      if (document.hidden) {
        gameRunning = false;
      } else {
        if (!gameEnded) {
          gameRunning = true;
          lastFrameTime = Date.now();
          requestAnimationFrame(renderLoop);
        }
      }
    };

    onMounted(async () => {
      if (canvasRef.value) {
        onResize();
        await preloadImages();
        if (readyPromiseResolve) {
          readyPromiseResolve();
        }

        await nextTick();
        initialize();

        const canvas = canvasRef.value;
        if (!canvas) return;
        containerBounds.value = canvas.getBoundingClientRect();

        canvas.addEventListener('resize', onResize);
        canvas.addEventListener('mousedown', onTouchStart);
        canvas.addEventListener('touchmove', onTouchMove, { passive: false });

        document.addEventListener('visibilitychange', visibilityChangeHandler);
      }
    });

    if (typeof window !== 'undefined') {
      window.addEventListener('mousemove', onTouchMove);
      window.addEventListener('touchstart', onTouchStart);
      window.addEventListener('scroll', onScroll);
      window.addEventListener('keyup', onKeyUp);
      window.addEventListener('keydown', onKeyDown);
    }

    onBeforeUnmount(() => {
      window.clearInterval(gameTimer);
      window.removeEventListener('touchstart', onTouchStart);
      window.removeEventListener('scroll', onScroll);
      window.removeEventListener('keyup', onKeyUp);
      window.removeEventListener('keydown', onKeyDown);
      window.clearInterval(intervalTimer);

      if (canvasRef.value) {
        const canvas = canvasRef.value;
        canvas.removeEventListener('scroll', onScroll);
        canvas.removeEventListener('resize', onResize);
        canvas.removeEventListener('mousedown', onTouchStart);
        canvas.removeEventListener('mousemove', onTouchMove);
        canvas.removeEventListener('touchmove', onTouchMove);
      }

      if (visibilityChangeHandler) {
        document.removeEventListener('visibilitychange', visibilityChangeHandler);
      }
    });

    return {
      canvasRef,
      height,
      onBeforeEnter: async () => {
        await readyPromise;
      },
      onAfterEnter: () => {
        containerBounds.value = canvasRef.value?.getBoundingClientRect();
        /**
         * Init the game after the flow animation is done, to make sure we can get the correct container size.
         * if rendered while animation is running it can get miscalculated container size.
         * Also fix timing issue with game starting before the animation is done between flow pages
         */
        startGame();
      }
    };
  }
});
</script>

<style lang="scss">
.bounce-battle-game {
  display: block;
  width: 100%;
  height: 100%;
  user-select: none;
  cursor: none;
}
</style>
